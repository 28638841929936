import Vue from 'vue'
import axios from 'axios'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { Toast } from 'vant'
import { Notify } from 'vant';

// axios.defaults.withCredentials = true;           //当前请求为跨域类型时是否在请求中协带cookie  true 会跨域报错
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 30000
})
Toast.setDefaultOptions({
    duration: 3000
})
NProgress.configure({ showSpinner: false })  //环形进度动画
// 请求拦截
service.interceptors.request.use(config => {
    // 启动进度条
    NProgress.start();
    let token = sessionStorage.getItem('token');
    config.baseURL = process.env.VUE_APP_BASE_API;
    if(token){
        config.headers = {
            "Authori-zation": "Bearer " + token
        }
    }
    return config;
}, error =>{
    console.log('请求拦截')
    return Promise.reject(error)
})

// 响应拦截
service.interceptors.response.use( response => {
    // 结束进度条
    NProgress.done();
    return response;
}, error => {
    NProgress.done();
    // 处理响应错误
    let errMsg = error.toString();
    let code = '';
    if (errMsg.indexOf('Network Error')){
        code = '网络错误!'
    }else{
        code = `${errMsg.substr(errMsg.indexOf('code') + 5)}！请联系管理员`;
    }
    Toast.clear();
    Notify({ type: 'danger', message: code });
    return Promise.reject(new Error(error));
})

export default service;
