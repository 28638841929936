import Vue from 'vue'
import store from '../store'

Vue.prototype.$statusRule = function (index, data) {
    // index  1 空驶完成取消不可操作
    let role = false;
    if(index == 1){
        if (data.status != 4 && data.status != 5 && data.status != 6 && data.status != 7 && data.status != 8 && data.status != 34){
            role = true
        }
    }
    return role
}

Vue.prototype.$jyTypeData = function (type, str) {
    let text;
    let typeDataConfig = store.state.typeDataConfig;
    for(let i=0; i<typeDataConfig.length; i++){
      if(typeDataConfig[i].no == type){
        switch(str){
          case 'name':
            text = typeDataConfig[i].short;
            break
          case 'type_icon':
            text = typeDataConfig[i].type_icon
            break
          case 'send_icon':
            text = typeDataConfig[i].send_icon
            break
          case 'service_icon':
            text = typeDataConfig[i].service_icon
            break
          case 'address':
            text = typeDataConfig[i].type
            break
          default:
            text = typeDataConfig[i].title;
            break
        }
      }
    }
    return text
}