<template>
  <div id="app">
    <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
export default {
  mounted(){
    this.$store.dispatch('getTypeDataConfig')
  }
}
</script>

<style lang="scss">
  #app{
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
</style>
